<template>
  <div class="homepage-5 the-search hd-white">
    <div id="wrapper">
      <!-- <section class="mt-4">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-6 pr-1 pt-1"></div>
          </div>
        </div>
      </section> -->
      <section
        v-lazyload
        :data-url="require('../assets/images/bg/home_section.webp')"
        class="mt-4 info-help h18 pedrem"
      >
        <div class="container">
          <div class="row info-head">
            <div class="col-lg-6 col-12 d-lg-none d-block">
              <h2
                style="
                  font-weight: 700;
                  text-transform: inherit;
                  font-family: futura;
                "
                class="text-dark text-center pb-lg-3 sec-head"
              >
                So what's it all about? <br />
                Find out here
              </h2>
            </div>
            <div class="col-lg-6 col-12">
              <div class="info-text d-flex justify-content-center">
                <div class="">
                  <video
                    class="res-video w-100 h-100"
                    id="video"
                    :src="require('../assets/images/Vencasa-Short-Ad-4K.mp4')"
                    :poster="require('../assets/images/vencasa-snap.png')"
                    webkit-playsinline
                    loop
                    controls
                    playsinline
                  ></video>
                  <div class="play-button-wrapper">
                    <div
                      title="Play video"
                      class="play-gif"
                      id="circle-play-b"
                      style="opacity: 1"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <h2
                style="
                  font-weight: 700;
                  text-transform: inherit;
                  font-family: 'futura';
                "
                class="
                  text-dark text-center
                  pt-4
                  pb-lg-3
                  sec-head
                  d-lg-block d-none
                "
              >
                So what's it all about? <br />
                Find out here
              </h2>
              <p class="text-center" style="font-weight: 650">
                Sign up for our referral program today and get <br /> £50 every time someone uses your code, <br /> and they get 50% off too
              </p>
              <div class="text-center">
                <router-link to="/register" class="btn btn-success px-5 py-2">
                  Sign Up Today
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="main">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-lg-8 col-12 my-5">
              <div class="row cart no-gutters pl-lg-0 pr-lg-3 px-2 py-4">
                <div class="col-lg-9 col-12 order-lg-first order-last">
                  <h3 class="text-center pt-3 pb-1">
                    We're not just another online agent!
                  </h3>
                  <p class="text-center">
                    Vencasa is a completely new way to sell your home online yourself, without the need for a middleman. You retain full control, deal directly with buyers, and save thousands in commission.
                  </p>
                </div>
                <div class="col-lg-3 col-12 order-lg-last order-first my-auto">
                  <div class="text-center mt-lg-0 mt-3">
                    <img
                      class="img2"
                      :src="require('../assets/images/idea_bulb.png')"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters justify-content-end">
            <div class="col-lg-8 col-12 mb-5">
              <div class="row cart no-gutters pr-lg-0 pl-lg-2 px-2 pt-4">
                <div class="col-lg-3 col-12 mt-3">
                  <div class="text-center">
                    <img
                      class="img2"
                      :src="require('../assets/images/v2_240.png')"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-12">
                  <h3 class="text-center pt-3 pb-1 w-100">
                    Did You Know The Average Commission <br />
                    Is 1.4% Plus Vat
                  </h3>
                  <p class="text-center">
                    The average commission today in the UK is 1.4% of the final sale price of your property, plus VAT at 20%. Vencasa charges you a flat rate up front, regardless of the price of your property. This saves you thousands in commission!
                  </p>
                  <h4 class="text-center pt-3 pb-1">
                    Dont's Believe us lets look at an example:
                  </h4>
                  <p class="text-center height-p">
                    The average thousand price is &#163;255,000 <br />
                    Using a traditional agent you will pay &#163;4284 <br />
                    Using Vencasa that amount would cover: <br />
                    Vencasa's Listing fee <br />
                    The stamp duty on another &#163;255,000 property <br />
                    Solicitors fees <br />
                    And <br />
                    You would still have around &#163;400 in your pocket to
                    spend on removals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-lg-8 col-12 mb-5">
              <div class="row cart no-gutters pl-lg-0 pr-lg-3 px-2 py-4">
                <div class="col-lg-9 col-12 order-lg-first order-last">
                  <h3 class="text-center pt-3 pb-1">Cut Out The Middle Man</h3>
                  <p class="text-center">
                    You maintain complete control over you'r sale, no more
                    waiting 2 days to hear back about an offer. Deal directly
                    with buyers using our secure internal messaging system.
                  </p>
                </div>
                <div class="col-lg-3 col-12 order-lg-last order-first my-auto">
                  <div class="text-center py-3">
                    <img
                      class="img2"
                      :src="require('../assets/images/v2_328.png')"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters justify-content-end">
            <div class="col-lg-8 col-12 mb-5">
              <div class="row cart no-gutters pr-lg-0 pl-lg-3 px-2 pt-2">
                <div class="col-lg-3 col-12 my-auto">
                  <div class="text-center py-3">
                    <img
                      class="img2"
                      :src="require('../assets/images/v2_333.png')"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-12">
                  <h3 class="text-center pt-3 pb-1 w-100">
                    Thousand of Potential Buyers
                  </h3>
                  <p class="text-center">
                    Our advertising is generating hundreds of thousand of new
                    potential buyers every month.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 2nd Section -->

        <div class="container mb-5">
          <div class="section2">
            <div class="row no-gutters">
              <div class="col-12">
                <h2
                  style="font-weight: 700; text-transform: capitalize"
                  class="text-light text-center pt-4 mt-1 pb-lg-3 sec-head"
                >
                  How Does It Work?
                </h2>
              </div>
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-lg-first order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step1.
                    </h3>
                    <p class="text-light text-center">
                      Sign Up For An Account Below To Get <br />
                      Started
                    </p>
                    <div class="text-center">
                      <router-link to="/register" class="btn btn-success px-5">
                        Sign Up
                      </router-link>
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step1.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-last order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_349.png')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters justify-content-end">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step2.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-first order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_366.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-lg-last order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step2.
                    </h3>
                    <p class="text-light text-center">
                      Tell us about your property. <br />
                      Follow our simple step by step <br />
                      guide that will lead you effortlessly <br />
                      through the listings process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-lg-first order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step3.
                    </h3>
                    <p class="text-light text-center">
                      Make payment. <br />
                      One simple up front set free. <br />
                      Listing will remain on our site until sold.
                    </p>
                  </div>
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step3.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-last order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_369.png')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters justify-content-end">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step4.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-first order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_372.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-lg-last order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step4.
                    </h3>
                    <p class="text-light text-center">
                      Show Off Your Property. <br />
                      Share your listing with freinds on <br />
                      social media. <br />
                      Message buyers directly and arrange <br />
                      viewings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-lg-first order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step5.
                    </h3>
                    <p class="text-light text-center">
                      Accept an offer. <br />
                      Once you are happy, accept an offer <br />
                      through our internal messaging system <br />
                      and hand over the legal bit to the <br />
                      solicitors.
                    </p>
                  </div>
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step5.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-last order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_378.png')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters justify-content-end pb-4 mb-1">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step6.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-first order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_381.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-lg-last order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step6.
                    </h3>
                    <p class="text-light text-center">
                      Relax <br />
                      Sit back and think about how to spend <br />
                      your hard earned savings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container pb-4">
          <div class="bg-dgreen">
            <div class="row">
              <div class="col-sm-12 d-md-none d-block order-0 pt-4 pb-3 px-3">
                <h2
                  style="font-weight: 700; text-transform: capitalize"
                  class="text-light text-center sec-head"
                >
                  Helping the Environment
                </h2>
              </div>
              <div class="col-sm-12 text-center my-md-4 order-md-0 order-1 pt-2 pb-2">
                <img
                  class="img3"
                  :src="require('../assets/images/v3_57.png')"
                  alt=""
                />
              </div>

              <div class="col-sm-12 order-md-1 order-2 pb-2">
                <h2
                  style="font-weight: 700; text-transform: capitalize"
                  class="text-light text-center sec-head d-md-block d-none"
                >
                  Helping the Environment
                </h2>
                <div class="mt-4">
                  <p class="text-light text-center">
                    Vencasa is committed to contributing to the protection of
                    the environment. <br /> That is why
                    we have partnered with <a style="color: white" target="_blank" href="https://ecologi.com/">ecologi.com</a>
                  </p>
                  <p class="text-light text-center">
                    We plant 16 trees for each listing and offer you the
                    opportunity to do even <br />
                    more by having the option to offset your move.
                  </p>
                  <p class="text-light text-center">
                    By planting trees, you are not only fighting the C02
                    problem, <br />
                    but also providing a home for animals for years to come.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container my-4">
          <div class="row justify-content-end mx-0">
            <div class="col-md-6 d-lg-block d-none my-auto mr-5">
              <img :src="require('../assets/images/dino.png')" alt="" />
            </div>
            <div class="col-lg-5 border border-dark">
              <div class="text-center">
                <h3 class="mt-2 account-h3" style="font-weight: 700;">
                  Create An Account to Get Started
                </h3>
                <p
                  class="mt-0 mb-2 text-center"
                  style="word-spacing: 5px; font-weight: 600"
                >
                  Once you sign up we will send you a unique referral code.
                  Share this code with your freinds and you will receive
                  &#163;50 every time someone uses it to pay for a listing
                  <br />
                  They will also receive 50% off
                </p>
                <div
                  class="
                    access_social
                    mx-lg-4 mx-md-2 mx-0
                    px-lg-5 px-md-4 px-4
                  "
                >
                  <!-- <a
                        @click="facebookLogin()"
                        href="#0"
                        class="social_bt facebook"
                        >Continue with Facebook</a
                      > -->
                  <a @click="googleLogin()" href="#0" class="social_bt google">
                    &nbsp; &nbsp; &nbsp; Continue with Google</a
                  >
                </div>
                <h4 style="font-weight: 700">OR <br> Sign Up <br> Manually</h4>
              </div>
              <div class="mx-4 px-lg-5 px-md-2 px-0">
                <form @submit.prevent="">
                  <div class="form-group">
                    <label><strong> Username</strong></label>
                      <input
                        :class="
                          $v.username.$dirty && !$v.username.required
                            ? 'error-border'
                            : ''
                        "
                        v-model="username"
                        class="form-control border-dark"
                        type="text"
                      />
                      <span
                        class="error"
                        v-if="$v.username.$dirty && !$v.username.required"
                        >This field is required!</span
                      >
                      <span class="error" v-if="usernameError"
                        >This username has already been taken</span
                      >
                  </div>

                  <div class="form-group">
                      <label><strong>First Name</strong></label>
                      <input
                        :class="
                          $v.firstName.$dirty && !$v.firstName.required
                            ? 'error-border'
                            : ''
                        "
                        v-model="firstName"
                        class="form-control border-dark"
                        type="text"
                      />
                      <span
                        class="error"
                        v-if="$v.firstName.$dirty && !$v.firstName.required"
                        >This field is required!</span
                      >
                    </div>
                    <div class="form-group">
                      <label><strong>Last Name</strong></label>
                      <input
                        :class="
                          $v.lastName.$dirty && !$v.lastName.required
                            ? 'error-border'
                            : ''
                        "
                        v-model="lastName"
                        class="form-control border-dark"
                        type="text"
                      />
                      <span
                        class="error"
                        v-if="$v.lastName.$dirty && !$v.lastName.required"
                        >This field is required!</span
                      >
                    </div>
                    <div class="form-group email">
                      <label><strong>Email</strong></label>
                      <input
                        :class="
                          $v.email.$dirty && !$v.email.required
                            ? 'error-border'
                            : ''
                        "
                        v-model="email"
                        class="form-control border-dark"
                        type="email"
                      />
                      <span
                        class="error"
                        v-if="$v.email.$dirty && !$v.email.required"
                        >This field is required!</span
                      >
                      <span class="error" v-if="emailError"
                        >This email has already been taken</span
                      >
                    </div>
                    <div class="form-group">
                      <label><strong>Password</strong></label>
                      <input
                        :class="
                          ($v.password.$dirty && !$v.password.required) ||
                          ($v.password.$dirty && !$v.password.minLength)
                            ? 'error-border'
                            : ''
                        "
                        v-model="password"
                        class="form-control border-dark"
                        type="password"
                        id="password1"
                      />
                      <span
                        class="error"
                        v-if="$v.password.$dirty && !$v.password.required"
                        >This field is required!</span
                      >
                      <span
                        class="error"
                        v-if="$v.password.$dirty && !$v.password.minLength"
                        >Password must be 8 digits long</span
                      >
                    </div>
                    <div class="form-group">
                      <label><strong>Confirm Password</strong></label>
                      <input
                        :class="
                          !$v.passwordConfirmation.sameAsPassword
                            ? 'error-border'
                            : ''
                        "
                        v-model="passwordConfirmation"
                        class="form-control border-dark"
                        type="password"
                        id="password2"
                      />
                      <span
                        class="error"
                        v-if="!$v.passwordConfirmation.sameAsPassword"
                        >passwords must match</span
                      >
                    </div>
                    <div id="pass-info" class="clearfix"></div>
                  <div class="d-flex justify-content-center">
                    <button
                      @click="register"
                      type="button"
                      class="btn btn-success btn-lg btn-block"
                    >
                      Sign Up Now
                    </button>
                  </div>
                  <div class="text-center mt-2 mb-4">
                    <h6 class="dis-block my-2">Already have an account?</h6>
                    <router-link to="/login" class="link" href="">sign in</router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <a data-scroll href="#wrapper" class="go-up" style="display: inline"
          ><i class="fa fa-angle-double-up" aria-hidden="true"></i
        ></a>
      </div>
      <div class="container blog-section bg-white">
        <div class="sec-title">
          <h2 style="font-weight: 700" class="mt-5">Articles &amp; Tips</h2>
          <p style="font-family: 'Montserrat', sans-serif; font-weight: 600">
            Read the latest news from our blog.
          </p>
        </div>
        <div class="news-wrap mb-5">
          <div class="row">
            <articles
              class="hover"
              v-for="blog in blogs ? blogs.slice(0, 3) : []"
              :key="blog.id"
              :blog="blog"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
const articles = () =>
  import(/* webpackChunkName: "pyioifsda9" */ "../components/articles");
export default {
  components: {
    articles,
  },
  computed: {
    ...mapGetters({
      blogs: "getBlogs",
    }),
  },
  data() {
    return {
      hasClicked: false,
      usernameError: false,
      emailError: false,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    username: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  watch: {
    email() {
      if (this.hasClicked) this.emailError = false;
    },
    username() {
      if (this.hasClicked) this.usernameError = false;
    },
  },
  methods: {
    googleLogin() {
      window.location =
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_SOCIAL_GOOGLE_REDIRECT_URL +
        "/get-started";
    },
    facebookLogin() {
      window.location =
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_SOCIAL_FACEBOOK_REDIRECT_URL +
        "/get-started";
    },
    async register() {
      if (this.$v.$invalid) {
        if (this.$v.firstName.$invalid) this.$v.firstName.$touch();
        if (this.$v.lastName.$invalid) this.$v.lastName.$touch();
        if (this.$v.username.$invalid) this.$v.username.$touch();
        if (this.$v.email.$invalid) this.$v.email.$touch();
        if (this.$v.password.$invalid) this.$v.password.$touch();
        if (this.$v.passwordConfirmation.$invalid)
          this.$v.passwordConfirmation.$touch();
      } else {
        this.hasClicked = true;
        this.$store.commit("setShowLoader", true);
        const data = {
          name: this.firstName + " " + this.lastName,
          email: this.email,
          password: this.password,
          user_name: this.username,
          password_confirmation: this.passwordConfirmation,
        };
        await this.$store.dispatch("register", data).then((res) => {
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            if (this.ref_code) {
              this.$store
                .dispatch("sendRequestIfCookie", this.ref_code)
                .then((res) => {
                  if (res == true) {
                    this.$router.push("/email-verification");
                  }
                });
            } else this.$router.push("/email-verification");
          } else if (res.data.code == 422 && res.data.errors.email) {
            this.emailError = true;
            document
              .querySelector(".email")
              .scrollIntoView({ behavior: "smooth" });
          } else if (res.data.code == 422 && res.data.errors.user_name) {
            this.usernameError = true;
            document
              .querySelector(".username")
              .scrollIntoView({ behavior: "smooth" });
          } else if (res.data.code == 422 && res.data.errors.password) {
            alert("The passwords must match");
          }
        });
      }
    },
  },
  async mounted() {
    this.$nextTick(() => {
      const video = document.getElementById("video");
      const circlePlayButton = document.getElementById("circle-play-b");

      function togglePlay() {
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }

      circlePlayButton.addEventListener("click", togglePlay);
      video.addEventListener("playing", function () {
        circlePlayButton.style.opacity = 0;
      });
      video.addEventListener("pause", function () {
        circlePlayButton.style.opacity = 1;
      });
    });
    if (!this.blogs) {
      this.$store.dispatch("blogs", { page: 1, perPage: 10 });
    }
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }

  .res-show-mobile {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .res-butt {
    display: none !important;
  }
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-video {
    height: 200px !important;
    width: 100% !important;
  }
  .res-login {
    width: 100% !important;
    margin: 0px !important;
  }

  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }

  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 265px !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    width: 250px !important;
    padding: 13px !important;
    margin: 0px !important;
  }

  .res-button-api {
    width: 320px !important;
    padding: 12px !important;
    margin: 13px !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 290px !important;
    max-width: 290px !important;
    margin-bottom: 10px !important;
  }
  div >>> .multiselect__select {
    left: 76% !important;
  }

  div >>> .multiselect {
    padding-left: 10px !important;
  }

  .res-postal-code {
    width: 289px !important;
    margin-left: 24px !important;
    padding-left: 32px !important;
  }
  .res-threedot {
    margin-left: 19px !important;
    width: 89.9% !important;
  }

  .res-margin-save-set {
    margin-top: 15% !important;
  }

  .sec-title {
    padding-bottom: 0px !important;
  }

  .res-mt-2 {
    margin-top: 40px !important;
  }

  .admin {
    display: none !important;
  }

  .res-threedot span {
    padding-right: 24px !important;
  }

  .res-bed-icon {
    position: absolute;
    left: 12.5% !important;
    z-index: 9999;
    top: 112px;
    top: 66% !important;
  }

  .res-bathroom-icon {
    left: 10% !important;
  }

  .res-garden-icon {
    left: 10% !important;
  }

  .res-parking-icon {
    left: 10% !important;
  }

  .res-swmin-icon {
    left: 10% !important;
  }

  .res-pl-0 {
    padding-left: 0px !important;
  }

  .res-button-prop {
    padding: 15px 35px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 802px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}
div >>> .vs--searchable .vs__dropdown-toggle {
  cursor: text;
  width: 200px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 0px 8px rgb(255 255 255 / 20%);
  height: 48px;
  padding: 0 34px 0 34px;
  line-height: 47px;
  font-weight: 400;
  color: #000;
  font-family: "Lato", sans-serif;
  background: white;
  word-wrap: normal;
  margin-right: 0px !important;
  margin-left: 15px;
}

.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
  height: 4.5em;
  content: counter(step);
  counter-increment: step;
  line-height: 4em;
  border: 6px solid green;
  display: block;
  text-align: center;
  margin: 10px auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}
.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}

div >>> .homepage-5 .btn-yellow:hover {
  background: #548f4d !important;
  background-color: #548f4d !important;
}

div >>> .multiselect__placeholder {
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__input {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__tags {
  margin-left: 15px;
  height: 50px;
  max-width: 202px;
  width: 202px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
  padding-top: 13px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0 8px rgb(255 255 255 / 20%);
  padding-left: 28px;
}

div >>> .multiselect__select:before {
  top: 85%;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__placeholder {
  padding-left: 4px;
}

/* arif kartoot */
/* *{
    box-sizing: content-box;
    margin: 0px;
    padding: 0%;
} */
h2, h3{
  font-family: 'futura';
}
p{
  font-family: 'futura';
}
.cart {
  background-color: rgb(190, 221, 190);
}
.cart h3 {
  font-weight: 700;
}
/* .cart p {
  font-weight: 650;
} */
.cart h4 {
  font-weight: 700;
}
.height-p {
  line-height: 3;
}
p {
  text-align: justify;
  margin: 20px;
}
.img2 {
  max-width: 80%;
  height: auto;
}
.img3 {
  width: 140px;
  height: 140px;
}
.section2 {
  background-color: rgba(0, 0, 0, 0.836);
}
.section3 {
  background-color: rgb(0, 117, 0);
}
.secimage {
  width: 20%;
}
.form-control {
  padding: 0px;
}
h6 {
  display: inline;
}
.btn {
  border-radius: 10px;
  background-color: #53814e;
  border: #53814e;
}
.bg-dgreen {
  background-color: #5a7b51;
}
.main {
  background-image: url(/img/big.6cb53051.webp) !important;
  background-size: cover !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  padding: 0px;
  background-repeat: no-repeat !important;
}
/* @media screen and (max-width: 1199px) {
  .res-video {
    width: 700px !important;
    height: 415px !important;
  }
} */

@media screen and (min-width: 992px) {
  .cart h3 {
    font-size: x-large;
  }
}
@media screen and (max-width: 991.5px) {
  /* .cart h3 {
    font-size: x-large;
  } */
  .img2 {
    width: 100px !important;
    height: 100px;
  }
}
@media screen and (max-width: 767.5px) {
  .height-p {
    line-height: 2;
  }
  .img3 {
    width: 100px !important;
    height: 100px;
  }
}
@media screen and (min-width: 767.5px) {
  .account-h3{
     font-size: 26px;
  }
}
@media screen and (max-width: 330px) {
  .dis-block {
    display: block;
  }
  .link {
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .sec-head {
    font-size: 1.3rem !important;
  }
}
</style>
